// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appVersion: 'new-style-v0.1',
  USERDATA_KEY: 'ChuongTrinhKhuyenMai',
  isMockEnabled: true,
  apiUrl: 'https://goalf-api.onrender.com',
  uploadUrl: 'https://media.metu.vn/media/upload',
  syncGoogleCalendarUrl: 'https://goalf-api.onrender.com/auth/google/redirect',
  vapidKey: 'BFzwHzOIUInC6tcDlOpX3_DxHRnL1nP5iwe5GNzIAaC6QyrvEpBmOXUH9sQ9hFF9yUr9Uml9y1rG0vDnSc2M3QE',
  firebase: {
    apiKey: 'AIzaSyBSgjqDUGxoa2_r6RnNnbC25fWUPssqYxc',
    authDomain: 'goalf-514b5.firebaseapp.com',
    projectId: 'goalf-514b5',
    storageBucket: 'goalf-514b5.appspot.com',
    messagingSenderId: '396729351101',
    appId: '1:396729351101:web:444c08976ae2b19c67b0d4',
    measurementId: 'G-5YN9X3LQDQ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
