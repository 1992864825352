import { ToastrService } from 'ngx-toastr';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HandleHttpMessageService {

  constructor(public toasterService: ToastrService) {}

  showSuccess(message: string, title?: string) {
    message = message || 'Thao tác thành công';
    this.toasterService.success(message, title);
  }

  showError(message: string, title?: string) {
    message = message || 'Thao tác không thành công. Vui lòng kiểm tra lại !';
    this.toasterService.error(message, title);
  }

  showInfo(message: string, title?: string) {
    this.toasterService.info(message, title);
  }

  showWarning(message: string, title?: string) {
    this.toasterService.warning(message, title);
  }

  showCustom(message: string, title?: string) {
    this.toasterService.show(message, title);
  }

  showHTMLMessage(message: string, title?: string) {
    this.toasterService.success(message, title, { enableHtml: true });
  }
}
