import {AuthModel} from './auth.model';
import {DepartmentModel} from '../../../pages/organization/_models/department.model';

export enum UserRole {
  admin = 'admin',
  manager = 'manager',
  member = 'member'
}

export class UserModel extends AuthModel {
  constructor(data: any = {}) {
    super();
    this.setUser(data);
  }
  id: number;
  fullName: string;
  email: string;
  password: string;
  status: UserStatusEnum;
  phone: string;
  avatar: string;
  hasAvatar: boolean;
  emailVerifiedTime: string;
  position: string;
  role: UserRole;
  active: boolean;
  createdTime: string;
  manager: UserModel;
  department: DepartmentModel;
  incompleteInformation: boolean;
  organization: Organization;
  hasUpdateInfo: boolean; // đã update info lần đầu hay chưa
  googleTokenInfo: GoogleTokenInfo;

  setUser(user: any) {
    this.id = user?.id ?? null;
    this.fullName = user?.name || '';
    this.email = user?.email || '';
    this.password = user?.password || '';
    this.status = user.active ? UserStatusEnum.active : UserStatusEnum.suspended;
    this.phone = user?.phone || '';
    this.avatar = user?.avatar || './assets/media/avatars/blank.png';
    this.hasAvatar = !!user?.avatar;
    this.emailVerifiedTime = user?.email_verified_time || null;
    this.position = user?.position || null;
    this.role = user?.role || null;
    this.active = !!user?.active;
    this.createdTime = user?.created_time || null;
    this.hasUpdateInfo = user?.first_update || null;

    this.manager = null;
    if (user.manager) {
      const manager = new UserModel();
      manager.setUser(user.manager);
      this.manager = manager;
    }
    this.department = user?.department || null;

    // this.incompleteInformation = !this.hasAvatar || !this.department || !this.position || !this.fullName || !this.phone;
    this.incompleteInformation = !this.hasAvatar || !this.fullName || !this.phone;
    if (user.organization) {
      this.organization = {
        id: user?.organization?.id,
        name: user?.organization?.name,
        size: user?.organization?.business_size,
        location: user?.organization?.business_areas,
      };
    }

    if (user.google_tokens) {
      this.googleTokenInfo = {
        accessToken: user?.google_tokens?.access_token,
        expiresIn: user?.google_tokens?.expiry_date,
        scope: user?.google_tokens?.scope,
        tokenType: user?.google_tokens?.token_type,
        refreshToken: user?.google_tokens?.refresh_token,
      };
    }
  }
}

export enum UserStatusEnum {
  active = 'active',
  suspended = 'suspended',
  deleted = 'deleted'
}

interface Organization {
  id: number;
  name: string;
  size: number;
  location: string;
}

interface GoogleTokenInfo {
  accessToken: string;
  expiresIn: number;
  scope: string;
  tokenType: string;
  refreshToken: string;
}
