import {Injectable} from '@angular/core';
import {HttpContext, HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {HandleHttpMessageService} from './modules/common/_services/handle-http-message.service';

const MESSAGE_RESPONSE = new HttpContextToken<{ content: string | null, isShow: boolean }>(() => {
  return {content: null, isShow: true};
});

export function messageResponse(mes: { content: string, isShow: boolean }) {
  return new HttpContext().set(MESSAGE_RESPONSE, mes);
}


@Injectable({
  providedIn: 'root'
})
export class InterceptService {

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(
    private handleMessage: HandleHttpMessageService,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('Authorization')) {
      return next.handle(request);
    }

    const authLocalStorageToken = localStorage.getItem(this.authLocalStorageToken) || '';
    let auth: any;
    if (authLocalStorageToken) {
      auth = JSON.parse(authLocalStorageToken);
    }

    let authenticationRequest = request.clone({
      headers: request.headers.set('Content-Type', 'application/json')
    });

    if (auth && auth.authToken) {
      authenticationRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${auth?.authToken}`)
      });
    }

    if (request.headers.has('Content-Type')) {
      authenticationRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${auth?.authToken}`)
      });
    }

    const context = request.context.get(MESSAGE_RESPONSE);

    return next.handle(authenticationRequest)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse && request.method !== 'GET' && context.isShow) {
            this.handleMessage.showSuccess(context.content as string);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMessage: string = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            // errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status === 401) {
              localStorage.clear();
              window.location.href = '/login';
            } else if (error.status === 403 || error.status === 400) {
              errorMessage = error.error.message;
            }
          }
          if (request.method !== 'GET') {
            this.handleMessage.showError(errorMessage);
          }
          // window.alert(errorMessage);
          return throwError(error);
        })
      );
  }
}
