import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const BASE_URL = new InjectionToken<string>('');

export type User = {
  id: number;
  name: string;
  email: string;
  avatar: string;
  active: boolean;
}

export type Staff = User & {
  department: string;
  position: string;
  manager: string;
}

export type ListStaffResponse = {
  count: number;
  data: Staff[];
}

export type Objective = {
  id: number;
  title: string;
  owner: User;
}

export type Obj = Objective & {
  period: 'year' | 'quarter' | 'month';
  year: number;
  quarter?: number;
  month?: number;
}

export type ListObjResponse = {
  count: number;
  data: Obj[];
}

export type OKRsPeriod = {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
}

export type OKRs = Objective & {
  period: OKRsPeriod;
}

export type ListOKRsResponse = OKRs[]

export type CreateFbRequestRequest = {
  recipient_ids: number[];
  content: string;
  okr_id?: number;
  objective_id?: number;
}

export type CreateFeedbackRequest = {
  level: '1' | '2';
  recipient_ids: number[];
  content: string;
  guide?: string;
  okr_id?: number;
  objective_id?: number;
}

export type ReactFeedbackRequest = {
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient,
    @Inject(BASE_URL)
    private baseUrl: string
  ) { }

  private url(path: string) {
    if (path.startsWith('/')) {
      path = path.slice(1)
    }

    return `${this.baseUrl}/${path}`
  }

  get<T>(path: string, params?: { [param: string]: any }): Observable<T> {
    return this.http.get<T>(this.url(path), { params })
  }

  post<T>(path: string, body: any | null): Observable<T> {
    return this.http.post<T>(this.url(path), body)
  }

  put<T>(path: string, body: any | null): Observable<T> {
    return this.http.put<T>(this.url(path), body)
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(this.url(path))
  }

  listStaff(): Observable<ListStaffResponse> {
    return this.get<ListStaffResponse>('/staffs')
  }

  listObj(): Observable<ListObjResponse> {
    return this.get<ListObjResponse>('/objectives')
  }

  listOKRs(): Observable<ListOKRsResponse> {
    return this.get<ListOKRsResponse>('/okrs')
  }

  createFeedbackRequest(data: CreateFbRequestRequest) {
    return this.post('/feedbackrequests', data)
  }

  createFeedback(data: CreateFeedbackRequest) {
    return this.post('/feedbacks', data)
  }

  createFeedbackForRequest(id: number, data: any) {
    return this.post(`/feedbackrequests/${id}/feedbacks`, data)
  }

  listFeedback(opts: any) {
    return this.get('/feedbacks', opts)
  }

  listRequestFeedback(opts: any) {
    return this.get('/feedbackrequests', opts)
  }

  reactFeedback(id: number, data: any) {
    return this.post(`/feedbacks/${id}/reactions`, data)
  }

  listEffort() {
    return this.get('/efforts')
  }

  listPerformance() {
    return this.get('/performances')
  }

  feedbackStat() {
    return this.get('/feedbacks/stat')
  }

  getCheckin(id: number) {
    return this.get(`/checkins/${id}`)
  }

  getOKRsCheckin(id: number) {
    return this.get(`/okrs/checkins/${id}`)
  }

  listFeedbackComment(id: number) {
    return this.get(`/feedbacks/${id}/comments`)
  }

  sendFeedbackComment(id: number, msg: string) {
    return this.post(`/feedbacks/${id}/comments`, { message: msg })
  }

}
