import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as viLang } from './modules/i18n/vocabs/vi';
// import { locale as chLang } from './modules/i18n/vocabs/ch';
// import { locale as esLang } from './modules/i18n/vocabs/es';
// import { locale as jpLang } from './modules/i18n/vocabs/jp';
// import { locale as deLang } from './modules/i18n/vocabs/de';
// import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import {NgSelectConfig} from '@ng-select/ng-select';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private config: NgSelectConfig,
    private router: Router
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      viLang,
      // chLang,
      // esLang,
      // jpLang,
      // deLang,
      // frLang
    );

    // ng-select config
    this.config.notFoundText = 'Không tìm thấy kết quả phù hợp';
    // this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same
    // bindValue in most of the place.
    // You can also override bindValue for the specified template
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    this.config.bindValue = 'value';

    let isShowOnboarding = localStorage.getItem('is_show_onboarding')
    if (!isShowOnboarding) {
      this.router.navigate(['/onboarding'])
    }
  }

  ngOnInit() {
    this.modeService.init();
  }
}
